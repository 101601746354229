"use strict";

import IMA from "./js/ima";
import {getParams, uuidv4} from "./js/nbutils";
import {circleProgress} from "jquery-circle-progress";
import "./js/numscroller-1.0";
import "./js/scripts-james";
import {populate_IMA} from "./js/scripts";

window.wz = {

    'IMA' : IMA,

    'getParams' : getParams,

    'uuidv4' : uuidv4


};

window.populate_IMA = populate_IMA;

if(process.env.NODE_ENV === 'development') {
  window.wzmode = 'DEV';
} else {
  window.wzmode = 'PROD';
}
