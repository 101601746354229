    // Variables to pull from API
    var websiteURL = 'www.aventri.com';

    var attraction1Conversion = 182;
    var attraction2Traffic = 85;
    var attraction3Drop = 134;
    var attraction4Views = 53;
    var attraction5Minutes = 46;

    var search1Contacts = 14.5;
    var search2OrganicConversion = 18.1;
    var search3PaidConversion = 33.3;

    var email1Open = 72.1;
    var email2Bounce = 177.2;
    var email3Click = 157.6;
    var email4Open = 150;

    var social1Conacts = 9;
    var social2Conversion = 293;
    var social3Twitter = 5;
    var social4Facebook = 4;





    //Color Variables
    var red = '#FF825F';
    var orange = '#E7A763';
    var green = '#3ad531';





    // Top Grade
    var grade = 90;


    // Update website title
    $(".grade span.website").html(websiteURL);

    if (grade >= 0 && grade <= 64) {
        var gradeColor = red;
        $("h1.grade-text span").html("POOR");
        $("h1.grade-text span, .grade span.website").addClass("red-text");
    } else if (grade >= 65 && grade <= 79) {
        var gradeColor = orange;
        $("h1.grade-text span").html("OK");
        $("h1.grade-text span, .grade span.website").addClass("orange-text");
    } else {
        var gradeColor = green;
        $("h1.grade-text span").html("GOOD");
        $("h1.grade-text span, .grade span.website").addClass("green-text");
    }

    $('.circle').circleProgress({
        value: grade * 0.01,
        size: 200,
        lineCap: 'round',
        fill: {
            color: gradeColor
        }
    }).on('circle-animation-progress', function(event, progress) {
        $(this).find('strong').html(Math.round(grade * progress) + '<i>%</i>');
    });




    //Section Grades
    function progress(percent, $element) {
        var progressBarWidth = percent * $element.width() / 100;
        $element.find('div').animate({ width: progressBarWidth }, 500);
    }

    progress(80, $('#progressBar'));