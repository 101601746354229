// Industry Benchmarks
var attraction1ConversionBenchmark = 2;
var attraction3DropBenchmark = 50;
var attraction4ViewsBenchmark = 3.5;
var attraction5MinutesBenchmark = 2.5;
var searchTrafficBenchmark = 41;
var search1ContactsBenchmark = 25;
var search2OrganicConversionBenchmark = 3;
var search3PaidConversionBenchmark = 3;
var email1OpenBenchmark = 24;
var email2BounceBenchmark = 0.9;
var email3ClickBenchmark = 2;
var email4ClickToOpenBenchmark = 13;
var social1ContactsBenchmark = 5;
var social2LinkedInBenchmark = 0.8;
var social3TwitterBenchmark = 2.2;
var social4FacebookBenchmark = 0.7;


//Color Variables
var red = '#FF825F';
var orange = '#E7A763';
var green = '#3ad531';


// Function to animate section grade bar
export function progress(percent, $element) {
    var progressBarWidth = percent * $element.width() / 100;
    $element.find('div').animate({ width: progressBarWidth }, 3000);
}

export function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = (elemTop + $(elem).height()) - 500;

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

export function populate_IMA(ima) {

    // Variables to pull from API
    var websiteURL = ima['hub_domain'];
    var ima_source = ima['ima_data'];

    try{var attraction1Conversion = (ima_source.website.vist_to_lead_conv.value * 100).toFixed(1);} catch {var attraction1Conversion = 0.0;}
    try{var attraction3Drop = (ima_source.website.bounce_rate.value * 100).toFixed(1);} catch {var attraction3Drop = 0.0;}
    try{var attraction4Views = ima_source.website.pageviews_per_session.value.toFixed(1);} catch {var attraction4Views = 0.0;}
    try{var attraction5Minutes = ima_source.website.minutes_per_visit.value.toFixed(1);} catch {var attraction5Minutes = 0.0;}
    try{var searchTraffic = (ima_source.website.organic_traffic.value * 100).toFixed(1);} catch {var searchTraffic = 0.0;}
    try{var search1Contacts = (ima_source.seosem.contacts_from_organic.value * 100).toFixed(1);} catch {var search1Contacts = 0.0;}
    try{var search2OrganicConversion = (ima_source.seosem.organic_conv_rate.value * 100).toFixed(1);} catch {var search2OrganicConversion = 0.0;}
    try{var search3PaidConversion = (ima_source.seosem.paidsearch_conv_rate.value * 100).toFixed(1);} catch {var search3PaidConversion = 0.0;}
    try{var email1Open = (ima_source.email_marketing.email_open_rate.value * 100).toFixed(1);} catch {var email1Open = 0.0;}
    try{var email2Bounce = (ima_source.email_marketing.email_bounce_rate.value * 100).toFixed(1);} catch {var email2Bounce = 0.0;}
    try{var email3Click = (ima_source.email_marketing.email_ctr.value * 100).toFixed(1);} catch {var email3Click = 0.0;}
    try{var email4ClickToOpen = (ima_source.email_marketing.email_click_to_open.value * 100).toFixed(1);} catch {var email4ClickToOpen = 0.0;}
    try{var social1Contacts = (ima_source.social.contacts_from_social.value * 100).toFixed(1);} catch {var social1Contacts = 0.0;}
    try{var social2LinkedIn = (ima_source.social.LinkedIn_conv_rate.value * 100).toFixed(1);} catch {var social2LinkedIn = 0.0;}
    try{var social3Twitter = (ima_source.social.Twitter_conv_rate.value * 100).toFixed(1);} catch {var social3Twitter = 0.0;}
    try{var social4Facebook = (ima_source.social.Facebook_conv_rate.value * 100).toFixed(1);} catch {var social4Facebook = 0.0;}

    function allInView() {
        if (isScrolledIntoView($("section.attraction"))) progress(attractionGrade, $('.progress-bar.attraction'));
        if (isScrolledIntoView($("section.search"))) progress(searchGrade, $('.progress-bar.search'));
        if (isScrolledIntoView($("section.email"))) progress(emailGrade, $('.progress-bar.email'));
        if (isScrolledIntoView($("section.social"))) progress(socialGrade, $('.progress-bar.social'));
    }

//Attraction Section Grades
    $(".attraction1-conversion").html(attraction1Conversion);
    $(".attraction1-conversion-benchmark").html(attraction1ConversionBenchmark);
    var attraction1ConversionPercentage = Math.min(100, Math.round((attraction1Conversion / attraction1ConversionBenchmark) * 100));
    $(".attraction1-conversion-percentage").html(attraction1ConversionPercentage);
    $(".attraction3-drop").html(attraction3Drop);
    $(".attraction3-drop-benchmark").html(attraction3DropBenchmark);
    var attraction3DropPercentage = Math.min(100, Math.round((attraction3DropBenchmark / attraction3Drop) * 100));
    $(".attraction3-drop-percentage").html(attraction3DropPercentage);
    $(".attraction4-views").html(attraction4Views);
    $(".attraction4-views-benchmark").html(attraction4ViewsBenchmark);
    var attraction4ViewsPercentage = Math.min(100, Math.round((attraction4Views / attraction4ViewsBenchmark) * 100));
    $(".attraction4-views-percentage").html(attraction4ViewsPercentage);
    $(".attraction5-minutes").html(attraction5Minutes);
    $(".attraction5-minutes-benchmark").html(attraction5MinutesBenchmark);
    var attraction5MinutesPercentage = Math.min(100, Math.round((attraction5Minutes / attraction5MinutesBenchmark) * 100));
    $(".attraction5-minutes-percentage").html(attraction5MinutesPercentage);
    var attractionGrade = (attraction1ConversionPercentage + attraction3DropPercentage + attraction4ViewsPercentage + attraction5MinutesPercentage) / 4;
    attractionGrade = Math.min(100, Math.round(attractionGrade));
    $(".sectors .attraction-average").html(attractionGrade);
    $(".category.attraction .grade .number").html(attractionGrade);
    $(".category.attraction .grade .number").attr("data-max", (attractionGrade));


//Search Section Grades
    $(".search-traffic").html(searchTraffic);
    $(".search-traffic-benchmark").html(searchTrafficBenchmark);
    var searchTrafficPercentage = Math.min(100, Math.round((searchTraffic / searchTrafficBenchmark) * 100));
    $(".search-traffic-percentage").html(searchTrafficPercentage);
    $(".search1-contacts").html(search1Contacts);
    $(".search1-contacts-benchmark").html(search1ContactsBenchmark);
    var search1ContactsPercentage = Math.min(100, Math.round((search1Contacts / search1ContactsBenchmark) * 100));
    $(".search1-contacts-percentage").html(search1ContactsPercentage);
    $(".search2-organic-conversion").html(search2OrganicConversion);
    $(".search2-organic-conversion-benchmark").html(search2OrganicConversionBenchmark);
    var search2OrganicConversionPercentage = Math.min(100, Math.round((search2OrganicConversion / search2OrganicConversionBenchmark) * 100));
    $(".search2-organic-conversion-percentage").html(search2OrganicConversionPercentage);
    $(".search3-paid-conversion").html(search3PaidConversion);
    $(".search3-paid-conversion-benchmark").html(search3PaidConversionBenchmark);
    var search3PaidConversionPercentage = Math.min(100, Math.round((search3PaidConversion / search3PaidConversionBenchmark) * 100));
    $(".search3-paid-conversion-percentage").html(search3PaidConversionPercentage);
    var searchGrade = (searchTrafficPercentage + search1ContactsPercentage + search2OrganicConversionPercentage + search3PaidConversionPercentage) / 4;
    searchGrade = Math.min(100, Math.round(searchGrade));
    $(".sectors .search-average").html(searchGrade);
    $(".category.search .grade .number").html(searchGrade);
    $(".category.search .grade .number").attr("data-max", (searchGrade));


//Email Section Grades
    $(".email1-open").html(email1Open);
    $(".email1-open-benchmark").html(email1OpenBenchmark);
    var email1OpenPercentage = Math.min(100, Math.round((email1Open / email1OpenBenchmark) * 100));
    $(".email1-open-percentage").html(email1OpenPercentage);
    $(".email2-bounce").html(email2Bounce);
    $(".email2-bounce-benchmark").html(email2BounceBenchmark);
    var email2BouncePercentage = Math.min(100, Math.round((email2BounceBenchmark / email2Bounce) * 100));
    $(".email2-bounce-percentage").html(email2BouncePercentage);
    $(".email3-click").html(email3Click);
    $(".email3-click-benchmark").html(email3ClickBenchmark);
    var email3ClickPercentage = Math.min(100, Math.round((email3Click / email3ClickBenchmark) * 100));
    $(".email3-click-percentage").html(email3ClickPercentage);
    $(".email4-click-to-open").html(email4ClickToOpen);
    $(".email4-click-to-open-benchmark").html(email4ClickToOpenBenchmark);
    var email4ClickToOpenPercentage = Math.min(100, Math.round((email4ClickToOpen / email4ClickToOpenBenchmark) * 100));
    $(".email4-click-to-open-percentage").html(email4ClickToOpenPercentage);
    var emailGrade = (email1OpenPercentage + email2BouncePercentage + email3ClickPercentage + email4ClickToOpenPercentage) / 4;
    emailGrade = Math.min(100, Math.round(emailGrade));
    $(".sectors .email-average").html(emailGrade);
    $(".category.email .grade .number").html(emailGrade);
    $(".category.email .grade .number").attr("data-max", (emailGrade));


//Social Section Grades
    $(".social1-contacts").html(social1Contacts);
    $(".social1-contacts-benchmark").html(social1ContactsBenchmark);
    var social1ContactsPercentage = Math.min(100, Math.round((social1Contacts / social1ContactsBenchmark) * 100));
    $(".social1-contacts-percentage").html(social1ContactsPercentage);
    $(".social2-linkedin").html(social2LinkedIn);
    $(".social2-linkedin-benchmark").html(social2LinkedInBenchmark);
    var social2LinkedInPercentage = Math.min(100, Math.round((social2LinkedIn / social2LinkedInBenchmark) * 100));
    $(".social2-linkedin-percentage").html(social2LinkedInPercentage);
    $(".social3-twitter").html(social3Twitter);
    $(".social3-twitter-benchmark").html(social3TwitterBenchmark);
    var social3TwitterPercentage = Math.min(100, Math.round((social3Twitter / social3TwitterBenchmark) * 100));
    $(".social3-twitter-percentage").html(social3TwitterPercentage);
    $(".social4-facebook").html(social4Facebook);
    $(".social4-facebook-benchmark").html(social4FacebookBenchmark);
    var social4FacebookPercentage = Math.min(100, Math.round((social4Facebook / social4FacebookBenchmark) * 100));
    $(".social4-facebook-percentage").html(social4FacebookPercentage);
    var socialGrade = (social1ContactsPercentage + social2LinkedInPercentage + social3TwitterPercentage + social4FacebookPercentage) / 4;
    socialGrade = Math.min(100, Math.round(socialGrade));
    $(".sectors .social-average").html(socialGrade);
    $(".category.social .grade .number").html(socialGrade);
    $(".category.social .grade .number").attr("data-max", (socialGrade));


    try {
        $(allInView);
        $(window).scroll(allInView);
    } catch(err) {}

// Assign color class to hero sector grades
    $(".hero__content div.sectors span.sector").each(function (index) {
        var heroIntroGrade = $(this).children('span.grade').html();
        if (heroIntroGrade >= 0 && heroIntroGrade <= 64) {
            $(this).addClass("red-text");
        } else if (heroIntroGrade >= 65 && heroIntroGrade <= 79) {
            $(this).addClass("orange-text");
        } else {
            $(this).addClass("green-text");
        }
    });


// Assign color class to category grade
    $(".category__intro span.grade").each(function (index) {
        var categoryIntroGrade = $(this).children('span.number').html();
        if (categoryIntroGrade >= 0 && categoryIntroGrade <= 64) {
            $(this).addClass("red-text");
            $(this).siblings().addClass("red-text");
        } else if (categoryIntroGrade >= 65 && categoryIntroGrade <= 79) {
            $(this).addClass("orange-text");
            $(this).siblings().addClass("orange-text");
        } else {
            $(this).addClass("green-text");
            $(this).siblings().addClass("green-text");
        }
    });


// Assign color class to individual grades
    $(".category__grades .grade h3").each(function (index) {
        var categoryGrade = $(this).children('span').html();
        if (categoryGrade >= 0 && categoryGrade <= 64) {
            $(this).addClass("red-text");
        } else if (categoryGrade >= 65 && categoryGrade <= 79) {
            $(this).addClass("orange-text");
        } else {
            $(this).addClass("green-text");
        }
    });


// Update hero section
    $(".grade span.website").html(websiteURL);

    var grade = (attractionGrade + searchGrade + emailGrade + socialGrade) / 4;

    if (grade >= 0 && grade <= 64) {
        var gradeColor = red;
        $("h1.grade-text span").html("POOR");
        $("h1.grade-text span, .grade span.website").addClass("red-text");
    } else if (grade >= 65 && grade <= 79) {
        var gradeColor = orange;
        $("h1.grade-text span").html("OK");
        $("h1.grade-text span, .grade span.website").addClass("orange-text");
    } else {
        var gradeColor = green;
        $("h1.grade-text span").html("GOOD");
        $("h1.grade-text span, .grade span.website").addClass("green-text");
    }

    $('.circle').circleProgress({
        value: grade * 0.01,
        size: 200,
        lineCap: 'round',
        fill: {
            color: gradeColor
        }
    }).on('circle-animation-progress', function (event, progress) {
        $(this).find('strong').html(Math.round(grade * progress) + '<i>%</i>');
    });

    // Initialize Animate on Scroll
    AOS.init();
}
