import {formatNum, getParams, uuidv4} from "./nbutils";

var wait_strings = ['Hang in there, your data is on its way!',
                    "We're still gathering your data...",
                    "Sorry this is taking so long, almost there!",
                    "Hold on, your data will be here soon!" ];

export default class IMA {
    constructor(url) {
        this.app = document.getElementById('root');
        this.base_url = url;
    }

    makeIMA(code) {
        this.imakey = uuidv4();

        var url = process.env.APP_BACKEND_ENDPOINT + '/?send_email=true&code=' + code + '&imakey=' + this.imakey;

        return new Promise((resolve, reject) => {
            fetch(url,{method:"POST"})
                .then(result => {
                    if(result.status == 200) {
                        resolve(result);
                    } else {
                        reject(result);
                    }
                })
        });
    }

    getIMA(imakey,num_tries,update_field) {
        if(num_tries == undefined) { num_tries = 7;}
        var count = 0;
        return new Promise((resolve, reject) => {
            function getIMArecursive() {
                IMA._getIMA('imakey',imakey)
                    .then(result => {
                        if(result.statusCode == 200) {
                            resolve(result);
                        } else if(result.statusCode != 200 && count < num_tries) {
                            count++;
                            setTimeout(function() {
                                if(update_field != undefined) {
                                    var f = update_field.innerHTML;
                                    while(f == update_field.innerHTML) {
                                        update_field.innerHTML = wait_strings[Math.floor(Math.random()*wait_strings.length)];
                                    }
                                }
                                getIMArecursive();
                            }, 7000);
                        } else {
                            window.location.href = '/error.html';
                        }
                    })
            }

            getIMArecursive();
        });
    }

    static compileIMA(source) {
        var ima = {};
        for (var section in source) {
            ima[section] = {};
            for (var i = 0; i < source[section].length; i++) {
                var entry = {};
                entry['label'] = source[section][i].label;
                entry['value'] = source[section][i].value;
                ima[section][source[section][i].id] = entry;
            }
        }
        return ima;
    }

    IMAredirect(imakey) {
        var ima_url = this.base_url + '?imakey=' + imakey;
        window.location.href = ima_url;
    }

    hsoauth_redirect() {

        var oauth_url = "https://app.hubspot.com/oauth/authorize?";
        oauth_url += "client_id=88144525-fc4b-4cfa-8e88-0e17d79adeb5";
        oauth_url += "&redirect_uri=" + this.base_url
        oauth_url += "&scope=contacts%20content%20business-intelligence%20oauth";

        window.location.href = oauth_url;
    }

    makeTables() {
        for (var section in window.ima) {
            this.app.appendChild(this.makeIMAtable(window.ima[section],window.benchmark[section],String(section),'newbreed'));
        }
    }

    static _getIMA(select_by,selector) {
        var url = process.env.APP_BACKEND_ENDPOINT + '/?output=JSON&' + encodeURIComponent(select_by) + '=' + encodeURIComponent(selector);

        return fetch(url,{method:"GET"}).then(response => {
          return response.json().then(data => {
            data.statusCode = response.status;
            return data;
          });
        });
    }

    makeIMAtable(source_list,benchmark_list,table_name,nbclientid) {
        const container = document.createElement('div');
        container.setAttribute('class', 'container');

        const table = document.createElement("TABLE");
        table.setAttribute("id", table_name);
        container.appendChild(table);

        var row = document.createElement("TR");
        var h1 = document.createElement("TH");
        h1.appendChild(document.createTextNode("Metric"));
        row.appendChild(h1);

        var h2 = document.createElement("TH");
        h2.appendChild(document.createTextNode("B2B Industry Benchmark"));
        row.appendChild(h2);

        var h3 = document.createElement("TH");
        h3.appendChild(document.createTextNode(nbclientid));
        row.appendChild(h3);

        var h4 = document.createElement("TH");
        h4.appendChild(document.createTextNode("Percent to Benchmark"));
        row.appendChild(h4);

        table.appendChild(row);

        for (var i = 0; i < source_list.length; i++) {
            row = document.createElement("TR");
            row.setAttribute("id", source_list[i].id);
            table.appendChild(row);

            var metric = document.createElement("TD");
            metric.appendChild(document.createTextNode(source_list[i].label));
            row.appendChild(metric);

            var benchmark = document.createElement("TD");
            var bench = benchmark_list.find(o => o.id === source_list[i].id);
            benchmark.appendChild(document.createTextNode(formatNum(bench.value)));
            row.appendChild(benchmark);

            var source = document.createElement("TD");
            source.appendChild(document.createTextNode(formatNum(source_list[i].value)));
            row.appendChild(source);

            var compare = document.createElement("TD");
            var comp = formatNum((source_list[i].value / bench.value),is_percent=true);
            compare.appendChild(document.createTextNode(comp));
            row.appendChild(compare)
        }

        return container;
    }


}
