    export function formatNum(num,is_percent) {
        is_percent = typeof is_percent !== 'undefined' ? is_percent : false;

        if(typeof(num) != 'number') { return num; }

        // If the number is less than 1, assume it's a percentage...
        if(num >= 1 && is_percent == false) {
            return String(num.toFixed(2));
        } else {
            return ((num * 100).toFixed(1) + "%");
        }
    }

    export function getParams(url) {
        var params = {};
        var parser = document.createElement('a');
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = pair[1];
        }

        if(JSON.stringify(params) == "{}") {
            return undefined;
        } else {
            return params;
        }

    }

    export function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        )
    }